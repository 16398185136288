<template>
  <div v-if="formattedReplies">
    <div class="error">{{ getReplyError }}</div>
    <div class="reply-window">
      <div class="error">{{ formError }}</div>
      <div v-for="reply in formattedReplies" :key="reply.id">
        <div class="single-reply">
          <div class="float-end">
            <span
              v-if="reply.commenterId == user.uid && !reply.requestDelete"
              class="btn btn-link text-danger"
              @click="handleReplyRequest(reply.id)"
            >
              request delete</span
            >
            <div v-if="reply.requestDelete">
              delete requested
            </div>
          </div>
          <div class="created-at">
            {{ reply.createdAt }}
          </div>
          <div class="d-flex justify-content-start align-items-baseline">
            <div class="name">
              {{ reply.commenterDisplayName }}
            </div>
            <div class="legal" v-if="reply.isHyping">
              *issuer founder, employee, or otherwise compensated to promote
              this offering*
            </div>
          </div>
          <div class="message">
            {{ reply.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="user && user.emailVerified">
    <div>
      <span
        v-if="!isShowingNewReply"
        class="btn"
        @click="isShowingNewReply = true"
      >
        Reply
      </span>
    </div>
    <form
      v-if="!isLoading && isShowingNewReply"
      @submit.prevent="handleReply"
      class="edit-form"
    >
      <div class="form-outline mb-4">
        <label>Reply</label>
        <textarea v-model="comment" required class="form-control"> </textarea>
      </div>
      <div class="form-outline mb-4">
        <label>Disclosure:</label>
        <div class="pt-2">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="founderAnswer"
              id="founder"
              v-model="isHyping"
              :value="true"
              required
            />
            <label class="form-check-label" for="founder"
              >I am a founder or an employee of this issuer or compensated in
              some way to promote this investment offering.</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="founderAnswer"
              id="notFounder"
              v-model="isHyping"
              :value="false"
            />
            <label class="form-check-label" for="notFounder"
              >I am not a founder or an employee of this issuer or compensated
              in some way to promote this investment offering</label
            >
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Reply</button>
        </div>
        <div class="col">
          <span class="btn btn-secondary" @click="isShowingNewReply = false">
            Cancel
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import getCollection from "@/composables/getCollection";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import useDocument from "@/composables/useDocument";
import { formatDistanceToNow } from "date-fns";

export default {
  props: ["commentId", "replyCount"],
  setup(props) {
    const comment = ref("");
    const isHyping = ref("");
    const formError = ref(null);
    const isPending = ref(false);
    const isShowingNewReply = ref(false);
    const { user } = getUser();

    const { error: useDocError, updateDoc: updateComment } = useDocument(
      "offeringComments",
      props.commentId
    );

    const {
      isLoading,
      error: getReplyError,
      documents: replies
    } = getCollection(`offeringComments/${props.commentId}/replies`, "", [
      "createdAt",
      "asc"
    ]);

    const { error: createReplyError, addDoc: addReply } = useCollection(
      `offeringComments/${props.commentId}/replies`
    );

    const formattedReplies = computed(() => {
      if (replies.value) {
        if (replies.value.length > 0) {
          return replies.value.map(reply => {
            let time = formatDistanceToNow(reply.createdAt.toDate());
            return { ...reply, createdAt: time };
          });
        } else {
          return null;
        }
      }
    });

    const handleReply = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addReply({
          comment: comment.value,
          isHyping: isHyping.value,
          commenterId: user.value.uid,
          commenterDisplayName: user.value.displayName,
          createdAt: timestamp()
        });
        if (createReplyError.value) {
          isPending.value = false;
          formError.value = createReplyError.value;
        } else {
          comment.value = "";
          console.log("ReplyCount: ", props.replyCount);
          await updateComment({
            updatedAt: timestamp(),
            updatedBy: user.value.uid,
            replyCount: props.replyCount + 1
          });
          if (useDocError.value) {
            formError.value = useDocError.value;
          } else {
            isShowingNewReply.value = false;
          }
          isPending.value = false;
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleReplyRequest = async replyId => {
      const { error: useDocError, updateDoc: updateReply } = useDocument(
        `offeringComments/${props.commentId}/replies`,
        replyId
      );
      formError.value = null;
      await updateComment({
        replyRequestDelete: true,
        updatedAt: timestamp(),
        updatedBy: user.value.uid
      });
      if (useDocError.value) {
        isPending.value = false;
        formError.value = useDocError.value;
      } else {
        await updateReply({
          requestDelete: true,
          requestDeleteBy: user.value.uid,
          requestedAt: timestamp()
        });
        if (useDocError.value) {
          formError.value = useDocError.value;
        }
      }
    };

    return {
      comment,
      isHyping,
      isShowingNewReply,
      formError,
      isLoading,
      getReplyError,
      formattedReplies,
      handleReply,
      handleReplyRequest,
      user
    };
  }
};
</script>

<style>
</style>