import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';

const useCollection = (collection) => {

  const error = ref(null);
  const isPending = ref(false);

  // add a new document
  const addDoc = async (doc) => {
    error.value = null;
    isPending.value = true;
    try {
      const res = await projectFirestore.collection(collection).add(doc);
      isPending.value = false;
      return res;
    }
    catch (err) {
      console.log(err.message);
      error.value = 'could not add the document';
      isPending.value = false;
    }
  };

  // See if the supplied ID is unique
  const isUniqueId = async (id) => {
    isPending.value = true;
    error.value = null;

    let docRef = projectFirestore.collection(collection).doc(id);
    return await docRef.get().then((doc) => {
      isPending.value = false;
      return !doc.exists;
    }).catch((err) => {
      error.value = 'Error checking for uniqueness: ', err.message;
      isPending.value = false;
      return false;
    });
  };

  // Add unique ID
  const addUniqueId = async (id, doc) => {
    error.value = null;
    try {
      let result = await isUniqueId(id);
      if (result) {
        const res = await projectFirestore.collection(collection).doc(id).set(doc);
        return res;
      } else {
        if (!error.value) {
          error.value = 'ID already exists';
        }
      }
    } catch (err) {
      error.value = "Error adding unique ID: ", err.message;
    }

  };

  return { error, isPending, addDoc, addUniqueId, isUniqueId };
};
export default useCollection;
