<template>
  <div v-if="offeringDocument">
    <embed
      class="d-none d-md-block"
      :src="offeringDocument.documentURL"
      type="application/pdf"
      width="100%"
      height="600px"
    />
    <a
      class="d-md-none"
      :href="offeringDocument.documentURL"
      download="OfferingDocument"
      >Download</a
    >
  </div>
</template>

<script>
import getDocument from "@/composables/getDocument";

export default {
  props: ["id", "documentId"],
  setup(props) {
    const { document: offeringDocument } = getDocument(
      "offeringDocuments",
      props.documentId
    );

    return { offeringDocument };
  }
};
</script>

<style>
</style>