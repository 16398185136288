<template>
  <div class="d-flex justify-content-center">
    <div class="disclosure bg-light">
      This offering is conducted through Round Here, LLC; SEC file number
      007-00284; Central Registration Depository (CRD) number 313211. The
      compensation paid to Round Here as an intermediary is (a) an initial fee
      paid by each issuer in the range of $1700 to $3000, an amount determined
      to cover costs of our escrow services provider over the course of the
      offering, and other costs of issuer onboarding, bad actor checks, and
      anti-money laundering (AML) checks, and (b) a success fee in the amount of
      7% to 10% of the amount of each escrow closing and funding of the
      investment.
      <br /><br />
      You may cancel your investment commitment until 48 hours before the
      deadline identified by the issuer. Round Here will notify you when the
      target offering amount has been met. At that time, if prior to the
      deadline, the issuer may close the offering early with notice to you about
      the new offering deadline at least five (5) business days prior to such
      new offering deadline ((absent a material change that would require an
      extension of the offering and reconfirmation of the investment
      commitment). If you do not cancel an investment commitment before the
      48-hour period prior to the offering deadline, your funds will be released
      to the issuer upon closing of the offering and you will receive securities
      in exchange for your investment.
      <br /><br />
      If total investment commitments do not equal or exceed the target at the
      deadline, no securities will be sold in the offering, investment
      commitments will be cancelled and committed funds will be returned. If the
      issuer makes a material change to the terms of this offering, we will
      provide notice to you by email. If you do not reconfirm your investment
      commitment within five (5) business days of receiving that notice, your
      investment commitment will be cancelled and committed funds will be
      returned to you.
      <br /><br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.disclosure {
  padding: 20px;
  font-size: 14px;
  max-width: 75%;
}
</style>