<template>
  <div>
    <div v-if="user && user.emailVerified">
      <transition name="fadeIn">
        <div v-if="!showNewComment">
          <span
            class="btn btn-primary scroll-margin"
            @click="showNewComment = !showNewComment"
          >
            New Question or Comment
          </span>
        </div>
      </transition>
      <transition name="fadeIn">
        <div v-if="showNewComment">
          <NewComment
            :offerId="offeringId"
            @close-new-comment="showNewComment = false"
          />
        </div>
      </transition>
    </div>
    <div v-else class="scroll-margin">
      <h6>You must validate your email address to comment or reply.</h6>
      <p>
        When you signed up, you received a validation email with a link to
        validate your email. If you don't have that email, you can request a new
        one on your account page,
        <router-link :to="{ name: 'Account' }">here</router-link>.
      </p>
    </div>

    <div class="error">{{ formError }}</div>
    <div v-if="commentError">{{ commentError }}</div>
    <div v-for="offeringComment in offeringComments" :key="offeringComment.id">
      <div class="float-end">
        <span
          v-if="
            offeringComment.commenterId == user.uid &&
              !offeringComment.requestDelete
          "
          class="btn btn-link text-danger"
          @click="handleRequest(offeringComment.id)"
        >
          request delete</span
        >
        <div v-if="offeringComment.requestDelete">
          delete requested
        </div>
      </div>
      <div class="head">
        {{ offeringComment.subject }}
      </div>
      <div class="d-flex justify-content-start align-items-baseline">
        <div class="name">
          {{ offeringComment.commenterDisplayName }}
        </div>
        <div class="legal" v-if="offeringComment.isHyping">
          *issuer founder, employee, or otherwise compensated to promote this
          offering*
        </div>
      </div>
      <div class="created-at">
        {{ offeringComment.date }}
      </div>
      <p>{{ offeringComment.comment }}</p>
      <div class="col-3">
        <div class="subject"></div>
      </div>
      <div class="col message"></div>
      <Replies
        :commentId="offeringComment.id"
        :replyCount="offeringComment.replyCount"
      />
    </div>
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import { ref } from "@vue/reactivity";
import Replies from "@/components/comments/Replies.vue";
import NewComment from "@/components/comments/NewComment.vue";
import getUser from "@/composables/getUser.js";
import useDocument from "@/composables/useDocument";
import { timestamp } from "../../firebase/config";

export default {
  props: ["offeringId"],
  components: { Replies, NewComment },
  setup(props) {
    const { user } = getUser();
    const formError = ref(null);
    const showNewComment = ref(false);
    const activeComment = ref("");
    const { error: commentError, documents: offeringComments } = getCollection(
      "offeringComments",
      [["offeringId", "==", props.offeringId]],
      ["createdAt", "asc"]
    );

    const handleRequest = async commentId => {
      const { error: useDocError, updateDoc: updateComment } = useDocument(
        "offeringComments",
        commentId
      );
      formError.value = null;
      await updateComment({
        requestDelete: true,
        requestDeleteBy: user.value.uid,
        requestedAt: timestamp()
      });
      if (useDocError.value) {
        formError.value = useDocError.value;
      }
    };

    return {
      showNewComment,
      activeComment,
      commentError,
      offeringComments,
      handleRequest,
      user,
      formError
    };
  }
};
</script>

<style scoped>
.fade-enter-from,
.fadeIn-enter-from {
  opacity: 0;
}
.fade-enter-to,
.fadeIn-enter-to {
  opacity: 1;
}
.fade-enter-active,
.fadeIn-enter-active {
  transition: all 1s ease;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 0.5s ease;
}

.scroll-margin {
  margin-top: 10px;
}
</style>