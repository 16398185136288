<template>
  <main>
    <div v-if="errorOffering" class="container page-content text-center">
      <h4>This offering is not currently published.</h4>
      <p>
        Once this offering is published, you can return here to view the
        details.
      </p>
      <router-link :to="{ name: 'Offerings' }" class="btn btn-lg btn-primary"
        >Current Offerings</router-link
      >
    </div>
    <div v-if="offering">
      <div
        v-if="!offering.published"
        class="container page-content text-center"
      >
        <h4>This offering is not currently published.</h4>
        <p>
          Once this offering is published, you can return here to view the
          details.
        </p>
        <router-link :to="{ name: 'Offerings' }" class="btn btn-lg btn-primary"
          >Current Offerings</router-link
        >
      </div>
      <div v-if="offering.published" class="container page-content">
        <div class="row pb-3">
          <div id="logo-holder">
            <img :src="offering.logoURL" class="card-img" />
          </div>
          <div class="col">
            <h1 id="offering-title">{{ offering.name }}</h1>
            <p id="offering-tagline">{{ offering.description }}</p>
          </div>
          <div class="col">
            <div v-if="isWatched">
              <span class="btn float-end" @click="removeFromWatch">
                <h4><i class="bi bi-bookmark-star-fill btn-link  "></i></h4>
              </span>
            </div>
            <div v-else>
              <span class="btn float-end" @click="addToWatch">
                <h4><i class="bi bi-bookmark-plus btn-link  "></i></h4>
              </span>
            </div>
          </div>
        </div>
        <!-- static content -->
        <div class="row">
          <div id="media" class="col-12 col-lg-8">
            <div class="row">
              <img :src="offering.mainURL" alt="Main Marketing Image" />
            </div>
            <div class="row meta-bar m-0 mb-5">
              <div class="col-12 col-md-6">
                <p class="meta-bar-label">
                  <b>Founded:</b> {{ offering.companyFounded }}
                </p>
                <p class="meta-bar-label">
                  <b>Headquarters:</b> {{ offering.issuerCity }},
                  {{ offering.issuerState }}<i class="bi bi-geo-alt-fill"></i>
                </p>
                <p class="meta-bar-label">
                  <b>Website: </b>
                  <a :href="offering.website" target="_blank"
                    >{{ offering.website }}
                  </a>
                  &nbsp;<i class="bi bi-arrow-up-right-square"></i>
                </p>
              </div>
              <div class="col-12 col-md-6">
                <a
                  v-if="offering.facebook"
                  :href="offering.facebook"
                  target="_blank"
                >
                  <i class="bi bi-facebook p-1"></i
                ></a>
                <a
                  v-if="offering.instagram"
                  :href="offering.instagram"
                  target="_blank"
                >
                  <i class="bi bi-instagram p-1"></i
                ></a>
                <a
                  v-if="offering.linkedIn"
                  :href="offering.linkedIn"
                  target="_blank"
                >
                  <i class="bi bi-linkedin p-1"></i
                ></a>
                <a
                  v-if="offering.twitter"
                  :href="offering.twitter"
                  target="_blank"
                >
                  <i class="bi bi-twitter p-1"></i
                ></a>
                <i class="bi bi-tiktok p-1"></i> <br />
                <div v-for="tag in offering.tags" :key="tag" class="pill">
                  <router-link
                    class="tag-link"
                    :to="{ name: 'OfferingsTag', params: { tag: tag } }"
                  >
                    #{{ tag }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div id="raise-meta" class="col-12 col-lg-4">
            <div class="col">
              <OfferingDetails :offering="offering" />
              <div class="row">
                <div
                  v-if="offering.isAcceptingInvestments"
                  class="col card-body"
                  style="text-align: center"
                >
                  <router-link
                    :to="{
                      name: 'OfferingInvest',
                      params: {
                        id: offering.id
                      }
                    }"
                    style="position: absolute, left: 0"
                    class="btn btn-primary btn-block"
                    >Invest Now</router-link
                  >
                </div>
                <div v-else>
                  <p>Not Accepting Investments at this time</p>
                </div>
              </div>
            </div>
          </div>

          <!-- MAIN CONTENT -->
          <div class="row mt-3 mb-5">
            <div class="col">
              <SubNav v-if="offering" class="sticky-md-top" :id="offering.id" />
              <div class="tab-pane" id="pitch">
                <h2>Pitch</h2>
                <div v-if="offeringPitch">
                  <p v-html="offeringPitch.highlights"></p>
                  <div
                    v-if="offeringPitch.videoLink"
                    class="video-responsive-youtube"
                  >
                    <iframe
                      width="100%"
                      height="315"
                      :src="offeringPitch.videoLink"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="overview">
                <div v-if="offeringPitch && offeringPitch.problem">
                  <h2>Problem</h2>
                  <p v-html="offeringPitch.problem"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.solution">
                  <h2>Solution</h2>
                  <p v-html="offeringPitch.solution"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.product">
                  <h2>Product</h2>
                  <p v-html="offeringPitch.product"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.businessModel">
                  <h2>Business Model</h2>
                  <p v-html="offeringPitch.businessModel"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.traction">
                  <h2>Traction</h2>
                  <p v-html="offeringPitch.traction"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.recognition">
                  <h2>Recognition</h2>
                  <p v-html="offeringPitch.recognition"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.market">
                  <h2>Market</h2>
                  <p v-html="offeringPitch.market"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.competition">
                  <h2>Competition</h2>
                  <p v-html="offeringPitch.competition"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.vision">
                  <h2>Vision</h2>
                  <p v-html="offeringPitch.vision"></p>
                </div>
                <div v-if="offeringPitch && offeringPitch.risks">
                  <h2>Risks</h2>
                  <p v-html="offeringPitch.risks"></p>
                </div>
              </div>
              <div class="tab-pane" id="team">
                <div class="error">{{ errorTeamMembers }}</div>
                <div
                  v-for="teamMember in teamMembers"
                  :key="teamMember.id"
                  class="row mt-4 mb-4"
                >
                  <div class="mt-3 mb-3 col-12 col-lg-3">
                    <img
                      class="bio-image-rounded"
                      :src="teamMember.headShotURL"
                    />
                  </div>

                  <div class="col-12 col-lg-9">
                    <h3>{{ teamMember.name }}</h3>
                    <p>
                      <b>{{ teamMember.title }}</b>
                    </p>
                    <p v-html="teamMember.biography"></p>
                    <p>
                      <i class="fab fa-linkedin-in meta-social-icons"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="documents">
                <h2>Documents</h2>
                <div class="error">{{ errorDocuments }}</div>
                <div
                  v-for="offeringDocument in offeringDocuments"
                  :key="offeringDocument.id"
                >
                  <span
                    class="btn-link"
                    @click="setDocumentView(offeringDocument.id)"
                  >
                    <div class="row single">
                      <div class="col-3">
                        <div class="subnav-thumbnail">
                          <img src="@/assets/img/pdf.png" />
                        </div>
                      </div>
                      <div class="col-4 name">
                        {{ offeringDocument.name }}
                      </div>
                      <div class="col message">
                        {{ offeringDocument.description }}
                      </div>
                    </div>
                  </span>
                  <OfferingDocument
                    v-if="documentShowing == offeringDocument.id"
                    :id="id"
                    :documentId="offeringDocument.id"
                  />
                </div>
              </div>
              <div class="tab-pane" id="disclosures">
                <h2>Disclosures</h2>
                <div class="error">{{ errorDisclosures }}</div>
                <div
                  v-for="offeringDisclosure in offeringDisclosures"
                  :key="offeringDisclosure.id"
                >
                  <span
                    class="btn-link"
                    @click="setDisclosureView(offeringDisclosure.id)"
                  >
                    <div class="row single">
                      <div class="col-3">
                        <div class="subnav-thumbnail">
                          <img src="@/assets/img/pdf.png" />
                        </div>
                      </div>
                      <div class="col-4 name">
                        {{ offeringDisclosure.name }}
                      </div>
                      <div class="col message">
                        {{ offeringDisclosure.description }}
                      </div>
                    </div>
                  </span>
                  <OfferingDocument
                    v-if="disclosureShowing == offeringDisclosure.id"
                    :id="id"
                    :documentId="offeringDisclosure.id"
                  />
                </div>
              </div>
              <div class="tab-pane" id="updates">
                <div
                  v-for="offeringUpdate in offeringUpdates"
                  :key="offeringUpdate"
                >
                  <h2>{{ offeringUpdate.title }}</h2>
                  <p>{{ offeringUpdate.date }}</p>
                  <div class="clearfix">
                    <img
                      v-if="offeringUpdate.mainImageURL"
                      :src="offeringUpdate.mainImageURL"
                      alt="Update Image"
                      class="col-md-4 mb-3 rounded-3 m-3 img-thumbnail float-sm-start"
                    />
                    <p v-html="offeringUpdate.description"></p>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="press">
                <div v-for="press in offeringPress" :key="press.id">
                  <h2>
                    <a :href="'https://' + press.url">{{ press.title }}</a>
                  </h2>
                  <p>
                    <em>{{ press.date }}</em>
                  </p>
                  <div class="clearfix">
                    <img
                      v-if="press.pressLogoURL"
                      :src="press.pressLogoURL"
                      alt="Update Image"
                      class="col-md-4 mb-3 rounded-3 m-3 img-thumbnail float-sm-start"
                    />
                    <p v-html="press.description"></p>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="qa">
                <Comments v-if="offering" :offeringId="id" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container -->
      <OfferingDisclosure v-if="offering || errorOffering" />
      <!-- MAKE THIS UNIVERSAL INCLUDE -->
      <div
        v-if="offering.isAcceptingInvestments && offering.published"
        class="d-none d-md-flex  container-fluid fixed-bottom shadow-lg bg-white justify-content-center"
      >
        <div class="m-3 pt-3">
          <p>
            <b>Deal Closes in {{ closingDateDistance }}</b>
          </p>
        </div>

        <div class="m-4">
          <router-link
            :to="{
              name: 'OfferingInvest',
              params: {
                id: offering.id
              }
            }"
            class="btn btn-primary btn-block"
            >Invest Now</router-link
          >
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="offering || errorOffering" />
</template>

<script>
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import Comments from "@/components/comments/Comments.vue";
import OfferingDocument from "@/components/offerings/OfferingDocument.vue";
import SubNav from "@/components/navigation/SubNav.vue";
import { ref, computed } from "@vue/reactivity";
import Footer from "@/components/navigation/Footer.vue";
import { formatDistanceToNow } from "date-fns";
import getUser from "@/composables/getUser.js";
import OfferingDetails from "@/components/offerings/OfferingDetails.vue";
import OfferingDisclosure from "@/components/offerings/OfferingDisclosure.vue";
import { arrayUnion, arrayRemove } from "@/firebase/config";
import useDocument from "@/composables/useDocument";

export default {
  props: ["id"],
  components: {
    Comments,
    SubNav,
    Footer,
    OfferingDocument,
    OfferingDetails,
    OfferingDisclosure
  },
  setup(props) {
    const documentShowing = ref(0);
    const disclosureShowing = ref(0);
    const { user } = getUser();
    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );
    const { error: errorOfferingPitch, document: offeringPitch } = getDocument(
      "offeringPitches",
      props.id
    );

    const { error: errorTeamMembers, documents: teamMembers } = getCollection(
      "teamMembers",
      [["offeringId", "==", props.id]],
      ["order", "asc"]
    );

    const {
      error: errorDocuments,
      documents: offeringDocuments
    } = getCollection(
      "offeringDocuments",
      [
        ["offeringId", "==", props.id],
        ["disclosure", "==", false]
      ],
      ["createdAt", "desc"]
    );

    const {
      error: errorDisclosures,
      documents: offeringDisclosures
    } = getCollection(
      "offeringDocuments",
      [
        ["offeringId", "==", props.id],
        ["disclosure", "==", true]
      ],
      ["createdAt", "desc"]
    );

    const { error: errorUpdates, documents: offeringUpdates } = getCollection(
      "offeringUpdates",
      [["offeringId", "==", props.id]],
      ["createdAt", "desc"]
    );

    const { error: errorPress, documents: offeringPress } = getCollection(
      "offeringPress",
      [["offeringId", "==", props.id]],
      ["date", "desc"]
    );

    const { error: offeringError, updateDoc: updateOffering } = useDocument(
      "offerings",
      props.id
    );

    const setDocumentView = docToView => {
      if (documentShowing.value == docToView) {
        documentShowing.value = 0;
      } else {
        documentShowing.value = docToView;
      }
    };

    const setDisclosureView = docToView => {
      if (disclosureShowing.value == docToView) {
        disclosureShowing.value = 0;
      } else {
        disclosureShowing.value = docToView;
      }
    };

    const closingDateDistance = computed(() => {
      if (offering.value) {
        return formatDistanceToNow(
          new Date(offering.value.offeringCloses).getTime()
        );
      } else {
        return null;
      }
    });

    const isWatched = computed(() => {
      if (offering.value.watchedBy) {
        return offering.value.watchedBy.includes(user.value.uid);
      } else {
        return false;
      }
    });

    const addToWatch = async () => {
      console.log("Add to watch list");
      await updateOffering({
        watchedBy: arrayUnion(user.value.uid)
      });
      if (offeringError.value) {
        errorOffering.value = offeringError.value;
      }
    };

    const removeFromWatch = async () => {
      console.log("Add to watch list");
      await updateOffering({
        watchedBy: arrayRemove(user.value.uid)
      });
      if (offeringError.value) {
        errorOffering.value = offeringError.value;
      }
    };

    return {
      errorOffering,
      offering,
      errorOfferingPitch,
      offeringPitch,
      errorTeamMembers,
      teamMembers,
      errorDocuments,
      offeringDocuments,
      errorDisclosures,
      offeringDisclosures,
      errorUpdates,
      offeringUpdates,
      errorPress,
      offeringPress,
      setDocumentView,
      setDisclosureView,
      documentShowing,
      disclosureShowing,
      closingDateDistance,
      addToWatch,
      removeFromWatch,
      isWatched
    };
  }
};
</script>

<style scoped>
/* ADD PADDING TO BOTTOM OF PAGE TO ACCOUNT FOR OVERLAY MENU */
footer.container {
  margin-bottom: 100px;
}

#logo-holder {
  width: 200px;
  padding-top: 10px;
}

.video-responsive-youtube {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive-youtube iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.carousel-item {
  max-height: 600px;
  text-align: center;
}

.carousel-item img {
  max-width: 100%;
}

#myCarousel {
  background: #eee;
}

/* raise-meta 
------------------------------------------------- */

.meta-bar {
  background: #eee;
  padding: 10px 5px;
  border-radius: 3px;
}

.meta-icons {
  text-decoration: none;
  margin-left: 7px;
}

.meta-tag {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  line-height: 0em;
}

.meta-bar-label {
  margin-bottom: 0.5em;
}

.meta-social-icons {
  font-size: 30px;
  background: #4ec1b4;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 5px;
  border-radius: 3px;
  margin-right: 3px;
}

.offering-link {
  text-decoration: none;
}

.bio-image-col {
  width: 220px;
}

.bio-image-rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}

.tab-pane {
  padding-top: 40px;
  padding-bottom: 40px;
}

.btn-link {
  text-decoration: none;
}
</style>