<template>
  <div class="card shadow-sm">
    <!-- Equity Raise Box -->
    <div v-if="offering.offeringType == 'equity'" class="card-body">
      <h2 class="card-title mb-3" id="raised-amount">
        <span>raised:</span><br />
        ${{ amountRaisedPretty }}
      </h2>
      <div class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="`width: ${raisePercent}%`"
          :aria-valuenow="offering.amountRaised"
          :aria-valuemin="0"
          :aria-valuemax="offering.raiseAmount"
        ></div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ investorCountPretty }}</span
            ><br />
            <small class="listing-stat-label">Investors</small>
          </p>
        </div>
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ preValuationPretty }}</span
            ><br />
            <small class="listing-stat-label">Valuation</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ pricePerSharePretty }}</span
            ><br />
            <small class="listing-stat-label">Price per Share</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ minInvestmentPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Invest</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMinPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Funding</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMaxPretty }}</span
            ><br />
            <small class="listing-stat-label">Max Funding</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{
              offering.offeringTypeDescription
            }}</span
            ><br />
            <small class="listing-stat-label">Shares Offered</small>
          </p>
        </div>
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ offering.offeringType }}</span
            ><br />
            <small class="listing-stat-label">Offering Type</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row mb-4">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ offering.offeringCloses }}</span
            ><br />
            <small class="listing-stat-label">Offering Closes</small>
          </p>
        </div>
      </div>
    </div>
    <!-- SAFE Raise Box -->
    <div
      v-if="
        offering.offeringType == 'debt' &&
          offering.offeringTypeDescription == 'SAFE'
      "
      class="card-body"
    >
      <h2 class="card-title mb-3" id="raised-amount">
        <span>raised:</span><br />
        ${{ amountRaisedPretty }}
      </h2>
      <div class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="`width: ${raisePercent}%`"
          :aria-valuenow="offering.amountRaised"
          :aria-valuemin="0"
          :aria-valuemax="offering.raiseAmount"
        ></div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ investorCountPretty }}</span
            ><br />
            <small class="listing-stat-label">Investors</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ minInvestmentPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Invest</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMinPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Funding</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMaxPretty }}</span
            ><br />
            <small class="listing-stat-label">Max Funding</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row mb-4">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ offering.offeringCloses }}</span
            ><br />
            <small class="listing-stat-label">Offering Closes</small>
          </p>
        </div>
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{
              offering.offeringTypeDescription
            }}</span
            ><br />
            <small class="listing-stat-label">Offering Type</small>
          </p>
        </div>
      </div>
    </div>
    <!-- Debt Raise Box -->
    <div
      v-if="
        offering.offeringType == 'debt' &&
          offering.offeringTypeDescription != 'SAFE'
      "
      class="card-body"
    >
      <h2 class="card-title mb-3" id="raised-amount">
        <span>raised:</span><br />
        ${{ amountRaisedPretty }}
      </h2>
      <div class="progress mb-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="`width: ${raisePercent}%`"
          :aria-valuenow="offering.amountRaised"
          :aria-valuemin="0"
          :aria-valuemax="offering.raiseAmount"
        ></div>
      </div>
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ investorCountPretty }}</span
            ><br />
            <small class="listing-stat-label">Investors</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ minInvestmentPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Invest</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span v-if="offering.interestRate === 0" class="listing-stats"
              >royalty-based</span
            >
            <span v-else class="listing-stats"
              >{{ offering.interestRate }}%</span
            >
            <br />
            <small class="listing-stat-label">Interest Rate</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{
              offering.offeringTypeDescription
            }}</span
            ><br />
            <small class="listing-stat-label">Offering Type</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMinPretty }}</span
            ><br />
            <small class="listing-stat-label">Min Funding</small>
          </p>
        </div>
        <div v-if="offering" class="col-6">
          <p class="card-text">
            <span class="listing-stats">${{ raiseAmountMaxPretty }}</span
            ><br />
            <small class="listing-stat-label">Max Funding</small>
          </p>
        </div>
      </div>
      <hr class="featurette-divider" />
      <div class="row mb-4">
        <div class="col-6">
          <p class="card-text">
            <span class="listing-stats">{{ offering.offeringCloses }}</span
            ><br />
            <small class="listing-stat-label">Offering Closes</small>
          </p>
        </div>
        <div class="col-6">
          <p class="card-text">
            <!-- <span class="listing-stats">{{ maturityDateDistance }}</span> -->
            <span class="listing-stats"
              >{{ offering.maturityYears }} years</span
            >
            <br />
            <small class="listing-stat-label">Maturity Time</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
// import { formatDistanceToNow } from "date-fns";

export default {
  props: ["offering"],
  setup(props) {
    const numberWithCommas = plainNumber => {
      if (plainNumber) {
        return plainNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return "0";
      }
    };

    const prettyNumbers = uglyNumber => {
      if (uglyNumber >= 1000000) {
        return Math.round((uglyNumber / 1000000) * 10) / 10 + "M";
      }
      if (uglyNumber > 1000) {
        return Math.round((uglyNumber / 1000) * 10) / 10 + "K";
      } else {
        return uglyNumber;
      }
    };

    const amountRaisedPretty = computed(() => {
      if (props.offering) {
        return numberWithCommas(props.offering.amountRaised);
      } else {
        return null;
      }
    });

    const investorCountPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.investorCount);
      } else {
        return null;
      }
    });

    const raiseAmountMaxPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.raiseAmountMax);
      } else {
        return null;
      }
    });

    const raiseAmountMinPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.raiseAmountMin);
      } else {
        return null;
      }
    });

    const preValuationPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.preValuation);
      } else {
        return null;
      }
    });

    const pricePerSharePretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.pricePerShare);
      } else {
        return null;
      }
    });

    const minInvestmentPretty = computed(() => {
      if (props.offering) {
        return prettyNumbers(props.offering.minInvestment);
      } else {
        return null;
      }
    });

    // const maturityDateDistance = computed(() => {
    //   if (props.offering) {
    //     if (props.offering.maturityDate)
    //       return formatDistanceToNow(
    //         new Date(props.offering.maturityDate).getTime()
    //       );
    //     else {
    //       return "";
    //     }
    //   } else {
    //     return null;
    //   }
    // });

    const raisePercent = computed(() => {
      if (props.offering.raiseAmount > 0) {
        let percent =
          (100 * props.offering.amountRaised) / props.offering.raiseAmount;
        return percent;
      } else {
        return 0;
      }
    });

    return {
      raiseAmountMinPretty,
      raiseAmountMaxPretty,
      amountRaisedPretty,
      investorCountPretty,
      preValuationPretty,
      pricePerSharePretty,
      minInvestmentPretty,
      // maturityDateDistance,
      raisePercent
    };
  }
};
</script>

<style scoped>
#raised-amount {
  font-size: 40px;
  color: #404042;
  margin-top: -25px;
}

#raised-amount span {
  font-size: 20px;
  color: #4ec1b4;
}

.card-text {
  margin-bottom: 0px;
}

.progress {
  height: 4px;
  margin-bottom: 10px;
}

.progress-bar {
  background-color: #4ec1b4;
}

.listing-stats {
  font-weight: 700;
  font-size: 19px;
  color: #404042;
}

.listing-stat-label {
  font-size: 15px;
  color: #777;
}
</style>