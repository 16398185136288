<template>
  <div class="sub-menu">
    <ul class="nav nav-pills flex-column flex-sm-row">
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#pitch' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Pitch</router-link
        >
      </li>

      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#overview' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Overview</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#team' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Team</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#documents' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Documents</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#disclosures' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Disclosures</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#updates' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Updates</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#press' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Press</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'Offering', params: { id: id }, hash: '#qa' }"
          class="flex-sm-fill text-sm-center nav-link"
          >Q&amp;A</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["id"]
};
</script>

<style scoped>
.sub-menu {
  padding-top: 4.5em;
  background: white;
}
</style>