<template>
  <div>
    <form @submit.prevent="handleSubmit" class="edit-form">
      <h6>Create a new question or comment</h6>
      <div class="form-outline mb-4">
        <label>Subject</label>
        <input type="text" required v-model="subject" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Question / Comment</label>
        <textarea v-model="comment" required class="form-control"> </textarea>
      </div>
      <div class="form-outline mb-4">
        <label>Disclosure:</label>
        <div class="pt-2">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="founderAnswer"
              id="founder"
              v-model="isHyping"
              :value="true"
              required
            />
            <label class="form-check-label" for="founder"
              >I am a founder or an employee of this issuer or compensated in
              some way to promote this investment offering.</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="founderAnswer"
              id="notFounder"
              v-model="isHyping"
              :value="false"
            />
            <label class="form-check-label" for="notFounder"
              >I am not a founder or an employee of this issuer or compensated
              in some way to promote this investment offering</label
            >
          </div>
        </div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div class="col" @click="closeNewComment">
          <span class="btn btn-secondary">
            Cancel
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-primary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import getUser from "@/composables/getUser.js";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import { format } from "date-fns";
export default {
  props: ["offerId"],
  emits: ["closeNewComment"],
  setup(props, context) {
    const isHyping = ref(null);
    const formError = ref(null);
    const isPending = ref(false);
    const subject = ref("");
    const comment = ref("");
    const { user } = getUser();
    const { error, addDoc } = useCollection("offeringComments");
    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await addDoc({
          date: format(new Date(), "MMM d, uuuu"),
          updatedAt: timestamp(),
          updatedBy: user.value.uid,
          subject: subject.value,
          comment: comment.value,
          offeringId: props.offerId,
          commenterId: user.value.uid,
          commenterDisplayName: user.value.displayName,
          isHyping: isHyping.value,
          replyCount: 0,
          replyRequestDelete: false,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (error.value) {
          formError.value = error.value;
        } else {
          closeNewComment();
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const closeNewComment = () => {
      context.emit("closeNewComment");
    };

    return {
      isHyping,
      formError,
      isPending,
      subject,
      comment,
      handleSubmit,
      closeNewComment
    };
  }
};
</script>

<style>
</style>